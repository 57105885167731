export const environment = {
  production: false,
  apyKeyGoogleMaps: 'AIzaSyAn5Mh7vph54UEgml925I4Hdhyw8pjnTiI',
  recaptchaKey: '6LesUFQpAAAAAAfpqU_mg3D1mqXNcIMvePMbuKnD',
  firebaseConfig: {
    apiKey: 'AIzaSyD8yrY3nalpLF4alv1uxKmhjTRf3rW6wZc',
    authDomain: 'dev-teclogi-fe69f.firebaseapp.com',
    databaseURL: 'https://dev-teclogi-fe69f-default-rtdb.firebaseio.com',
    projectId: 'dev-teclogi-fe69f',
    storageBucket: 'dev-teclogi-fe69f.appspot.com',
    messagingSenderId: '751127021270',
    appId: '1:751127021270:web:1477a210e9169b058deb77',
    measurementId: 'G-XKHX0D3STK',
    vapidKey: 'AwOLRT_Y9SL7qTVcXaDWSrNPWNe6aDsMOySNuzy3kuY'
  },
  urlServerTeclogi: 'https://test.teclogi.com/Teclogi/services/',
  rootNit: '9013312320',
  listFirebaseConfig: {
    "default": {
      apiKey: "AIzaSyA0HqucvjmVHKi-NjWMtkM9g1QHIRJ8doM",
      authDomain: "dev-loggiapp.firebaseapp.com",
      databaseURL: "https://dev-loggiapp-default-rtdb.firebaseio.com",
      projectId: "dev-loggiapp",
      storageBucket: "dev-loggiapp.appspot.com",
      messagingSenderId: "285163060202",
      appId: "1:285163060202:web:1e62ac656c385aff399093",
      measurementId: "G-9EFWP0Z8PJ"
    },
    "9013312320": {
      apiKey: 'AIzaSyD8yrY3nalpLF4alv1uxKmhjTRf3rW6wZc',
      authDomain: 'dev-teclogi-fe69f.firebaseapp.com',
      databaseURL: 'https://dev-teclogi-fe69f-default-rtdb.firebaseio.com',
      projectId: 'dev-teclogi-fe69f',
      storageBucket: 'dev-teclogi-fe69f.appspot.com',
      messagingSenderId: '751127021270',
      appId: '1:751127021270:web:1477a210e9169b058deb77',
      measurementId: 'G-XKHX0D3STK',
      vapidKey: 'AwOLRT_Y9SL7qTVcXaDWSrNPWNe6aDsMOySNuzy3kuY'
    },
    "9009704395": {
      apiKey: "AIzaSyCTf3eIo6iVjmQlXsnrXB_xPQqPDm-6oIE",
      authDomain: "bulkmatic-6e588.firebaseapp.com",
      databaseURL: "https://bulkmatic-6e588-default-rtdb.firebaseio.com",
      projectId: "bulkmatic-6e588",
      storageBucket: "bulkmatic-6e588.appspot.com",
      messagingSenderId: "672229596823",
      appId: "1:672229596823:web:c786b7182c9da5458793d2",
      measurementId: "G-H9ELCZNBKY"
    },
    "9011981505": {
      apiKey: "AIzaSyD28jRSRcfkNob7U7Z0sAd_ZFtzLvArAlA",
      authDomain: "invertraico-21103.firebaseapp.com",
      databaseURL: "https://invertraico-21103-default-rtdb.firebaseio.com",
      projectId: "invertraico-21103",
      storageBucket: "invertraico-21103.appspot.com",
      messagingSenderId: "595336069398",
      appId: "1:595336069398:web:c4f011acd976d412b75037"
    },
    "9007387182": {
      apiKey: "AIzaSyCxDs0VldeX6YMJi41MNoAi4HfSYnHn3fg",
      authDomain: "jl-y-rb.firebaseapp.com",
      databaseURL: "https://jl-y-rb-default-rtdb.firebaseio.com",
      projectId: "jl-y-rb",
      storageBucket: "jl-y-rb.appspot.com",
      messagingSenderId: "182554054550",
      appId: "1:182554054550:web:687ac7e0a57faea1d717b7",
      measurementId: "G-9QE13DTJDR"
    },
    "9003410860": {
      apiKey: "AIzaSyCRIMn2ohtFTyOIoQhW7V1EqYWVk8OMAFs",
      authDomain: "nutresa-f7461.firebaseapp.com",
      databaseURL: "https://nutresa-f7461-default-rtdb.firebaseio.com",
      projectId: "nutresa-f7461",
      storageBucket: "nutresa-f7461.appspot.com",
      messagingSenderId: "769602149935",
      appId: "1:769602149935:web:601aee15b0cc07d54370da",
      measurementId: "G-QPRW543WWX"
    },
    "9014171480": {
      apiKey: "AIzaSyCkLPqn-kvfGOEglrp5-k1J6hfreTYo8HU",
      authDomain: "loggiapp-core-dev.firebaseapp.com",
      databaseURL: "https://loggiapp-core-dev-default-rtdb.firebaseio.com",
      projectId: "loggiapp-core-dev",
      storageBucket: "loggiapp-core-dev.appspot.com",
      messagingSenderId: "586410054861",
      appId: "1:586410054861:web:ecd2803680b78ef1bb0584",
      database: "cargo-paramo"
    },
    "8000130291": {
      apiKey: "AIzaSyCMwjUYq_i6Z_rjYj1E_CHIxU-NM4w2XMk",
      authDomain: "transporte-la-estrella.firebaseapp.com",
      databaseURL: "https://transporte-la-estrella-default-rtdb.firebaseio.com",
      projectId: "transporte-la-estrella",
      storageBucket: "transporte-la-estrella.appspot.com",
      messagingSenderId: "71603871948",
      appId: "1:71603871948:web:6fc4d2f04ec7cefa86a269",
      measurementId: "G-6WND08LXTF"
      /*apiKey: "AIzaSyCkLPqn-kvfGOEglrp5-k1J6hfreTYo8HU",
      authDomain: "loggiapp-core-dev.firebaseapp.com",
      databaseURL: "https://loggiapp-core-dev-transporte-la-estrella.firebaseio.com",
      projectId: "loggiapp-core-dev",
      storageBucket: "loggiapp-core-dev.appspot.com",
      messagingSenderId: "586410054861",
      appId: "1:586410054861:web:ecd2803680b78ef1bb0584",
      database: "transporte-la-estrella"*/
    },
    "9013522401": {
      apiKey: "AIzaSyAvUcat6nPKY5TcdKzOcQemKHNc_JctzTc",
      authDomain: "seguridad-extrema-436017.firebaseapp.com",
      databaseURL: "https://seguridad-extrema-436017-default-rtdb.firebaseio.com",
      projectId: "seguridad-extrema-436017",
      storageBucket: "seguridad-extrema-436017.appspot.com",
      messagingSenderId: "80224250664",
      appId: "1:80224250664:web:4910105d7821939cd73e1d"
    },
    "9012861321": {
      apiKey: "AIzaSyDHIkr95vSROEPnoV2KPwpT9BBCutHZqrI",
      authDomain: "transalud.firebaseapp.com",
      databaseURL: "https://transalud-default-rtdb.firebaseio.com",
      projectId: "transalud",
      storageBucket: "transalud.appspot.com",
      messagingSenderId: "183237928965",
      appId: "1:183237928965:web:58e95e41f48fa663bf146c"
    },
    "9011282346": {
      apiKey: "AIzaSyCFB73DNBv0ayHNMS3cJpCSif7vMC5-Maw",
      authDomain: "transportes-carrillo.firebaseapp.com",
      databaseURL: "https://transportes-carrillo-default-rtdb.firebaseio.com",
      projectId: "transportes-carrillo",
      storageBucket: "transportes-carrillo.appspot.com",
      messagingSenderId: "916120310071",
      appId: "1:916120310071:web:fb0e49cd4f813717d38ab2"
    },
    "9007435921": {
      apiKey: "AIzaSyC5nOpFJtsSZXQzfRIhWbRBupOU4u0EyP0",
      authDomain: "opperar-colombia.firebaseapp.com",
      databaseURL: "https://opperar-colombia-default-rtdb.firebaseio.com",
      projectId: "opperar-colombia",
      storageBucket: "opperar-colombia.appspot.com",
      messagingSenderId: "953781737147",
      appId: "1:953781737147:web:abb30f3660b9f1cc1a7b9e"
    },
    "9016235289": {
      apiKey: "AIzaSyC5kkSJo-w5eRPAvPYwMw8McIUdFWscsWM",
      authDomain: "grupo-operativo-golti.firebaseapp.com",
      databaseURL: "https://grupo-operativo-golti-default-rtdb.firebaseio.com",
      projectId: "grupo-operativo-golti",
      storageBucket: "grupo-operativo-golti.firebasestorage.app",
      messagingSenderId: "1035804889206",
      appId: "1:1035804889206:web:46d97bee9bfd1e2728cf57"
    },
    "9009583341": {
      apiKey: "AIzaSyA3BmTRh8N2YtmkBRxBu8LCJUXKkgbpIRo",
      authDomain: "hurgotransporte.firebaseapp.com",
      databaseURL: "https://hurgotransporte-default-rtdb.firebaseio.com",
      projectId: "hurgotransporte",
      storageBucket: "hurgotransporte.firebasestorage.app",
      messagingSenderId: "866122803267",
      appId: "1:866122803267:web:6fb967d92c0ff6b2ea4cf6"
    },
    "9015266765": {
      apiKey: "AIzaSyDo2qewL2MBIb6-mEbH4dfVATIi-EyCUoA",
      authDomain: "valpa-transportadora.firebaseapp.com",
      databaseURL: "https://valpa-transportadora-default-rtdb.firebaseio.com",
      projectId: "valpa-transportadora",
      storageBucket: "valpa-transportadora.firebasestorage.app",
      messagingSenderId: "932076364234",
      appId: "1:932076364234:web:93f0f3c3106009aaf32f33"
    },
  },
  ignoredGPSByMonitor: ['SATRACK'],
  urlServerLoggiapp: "https://api.lab.loggiapp.com"
};
